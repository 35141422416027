import React from "react";
import { Link } from "gatsby";
import {
  TiSocialFacebook,
  TiSocialTwitter,
  TiSocialPinterest,
  TiSocialLinkedin
} from "react-icons/ti";
import { IoIosArrowRoundForward } from "react-icons/io";
import Swatch from 'sortd_sections/NewDocs/Swatch';
import Article from 'sortd_sections/NewDocs/Article';
import { Layout as AntLayout, Menu, Tooltip, Affix, Select, Card, Avatar, Popover } from 'antd';
import SEO from 'sortd_sections/shared/SEO';
import ReadingTime from 'sortd_sections/NewDocs/ReadingTime';
import Img from "gatsby-image"

import { Container, Row, Col } from "reusecore/Layout";
import VintageBox from "reusecore/VintageBox";
import Button from "reusecore/Button";
import PageHeader from "reusecore/PageHeader";
import Sidebar from "sortd_sections/blog/Blog-sidebar";

import BlogThumb from "assets/images/blog/post/blog-post-5.png";

import AuthorThumb2 from "assets/images/app/testimonial/testimonial-thumb-2.jpg";
import AuthorThumb3 from "assets/images/app/testimonial/testimonial-thumb-3.jpg";
import AuthorThumb4 from "assets/images/app/testimonial/testimonial-thumb-4.jpg";
import AuthorThumb5 from "assets/images/app/testimonial/testimonial-thumb-5.jpg";

import BlogPageWrapper from "./blogSingle.style";
import rehypeReact from "rehype-react";
import styles from './markdown.module.less';

const BlogSingle = ({
    data,
    location
}) => {
    // https://github.com/antvis/gatsby-theme-antv/issues/114
    const parseTableOfContents = (tableOfContents) => {
        return tableOfContents.replace(/\/#/g, '#');
    };
  
    const {pathname} = location;
    //const [prev, next] = usePrevAndNext();
    const { markdownRemark, allMarkdownRemark } = data; // data.markdownRemark holds our post data
    if (!markdownRemark) {
      return null;
    }
    const {
      frontmatter,
      htmlAst,
      tableOfContents,
      fields: { slug, readingTime },
    } = markdownRemark;

    let featuredImgFluid = frontmatter.thumbnail.childImageSharp.fluid

    const { edges = [] } = allMarkdownRemark;
    const blogs = edges.map(({ node, next, previous }) => {
        const { fields: nodeFields } = node;
        return {
          slug: nodeFields.slug,
          meta: {
            ...node.frontmatter,
            slug: nodeFields.slug,
            filename: nodeFields.slug,
          },
          ...node.frontmatter,
          ...node.fields,
          filename: nodeFields.path,
          next,
          previous,
        };
      });

      const renderAst = new rehypeReact({
        createElement: React.createElement,
        components: {
          swatch: Swatch,
        },
      }).Compiler;

  return (
    <BlogPageWrapper>
    <SEO title={frontmatter.title} />

    <AntLayout
        style={{ background: '#fff' }}
        hasSider
        className={styles.layout}
    >
            <Article className={styles.markdown}>
            <Affix offsetTop={88} >
            <div
              className={styles.toc}
              /* eslint-disable-next-line react/no-danger */
              dangerouslySetInnerHTML={{
                __html: parseTableOfContents(tableOfContents),
              }}
            />
          </Affix>
                <Container>
                <div className="single-post-wrapper">
                <PageHeader
        title={frontmatter.title}
        author={{ name: frontmatter.author, profile: "#" }}
      />
                    <Row>
                        <Col xs={12} lg={8}>
                            <Row>
                                <Col xs={12}>
                                <div className="single-post-block">
                    <div className="post-thumb-block">
                    <Img fluid={featuredImgFluid} className={"gatsbyHero"}/>
                      <div className="post-meta">
                        <span>
                          <em> {frontmatter.time.split(" ")[0]} </em> {frontmatter.time.split(" ")[1].toUpperCase()} 
                        </span>
                      </div>
                    </div>
                    <div className="post-content-block">
                                        <div className={styles.content}>
                                            {renderAst(htmlAst)}
                                        </div>
                                        </div>
                                        </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={12} lg={4}>
                            <Sidebar data={blogs} />
                        </Col>
                    </Row>
                    </div>

                </Container>
            </Article>
    </AntLayout>
    </BlogPageWrapper>
  );
};

export default BlogSingle;


export const pageQuery = graphql`
query TemplateBlogMarkdown($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
        htmlAst
        tableOfContents
        frontmatter {
            tags
            title
            time(formatString: "DD MMM 'YY")
            order
            type
            thumbnail{
                      childImageSharp {
                        fluid(maxWidth: 800) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
            author
            tags
        }
        fields {
            path
            slug
            modifiedTime
            avatarList
            readingTime {
                text
                time
            }
        }
    }
    allMarkdownRemark(
        filter: {fileAbsolutePath: {regex: "/blog/"}, fields: {slug: {}}, frontmatter: {}}, 
        sort: {fields: [frontmatter___order, frontmatter___type], order: DESC}) {
        edges {
            node {
                frontmatter {
                    title
                    order
                    type
                    time(formatString: "DD MMM 'YY")
                    thumbnail{
                      childImageSharp {
                        fluid(maxWidth: 800) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                    intro
                    author
                    tags
                }
                fields {
                    slug
                    path
                    modifiedTime
                    readingTime {
                        text
                    }
                }
            }
        }
    }
}
`;
